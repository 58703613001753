export default async function(path, match) {
  const progress = document.querySelector('#progress');
  const contentNode = document.querySelector('#content');

  progress.classList.toggle('d-none');

  const { default: Page } = await import(/* webpackChunkName: "chunk-[index]-[request]" */`../pages/${path}/index.js`);
  const search = window.location.search;
  const page = new Page({ match, search });

  contentNode.replaceChildren(page.element); // same: contentNode.innerHTML = ''; contentNode.append(page.element);

  progress.classList.toggle('d-none');

  window.dispatchEvent(new CustomEvent('route-changed', {
    detail: match
  }));

  return page;
}
