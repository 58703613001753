import renderPage from './render-page.js';

class Router {
  routes = [];

  static stripTrailingSlashes = (path) => {
    return path.replace(/^\/+|\/+$/g, '')
  }

  onRoute = async () => {
    const decodedPath = decodeURI(window.location.pathname);
    const clearedPath = Router.stripTrailingSlashes(decodedPath);
    const {path, match} = this.findRoute(clearedPath);

    this.page = await this.changePage(path || this.notFoundPagePath, match);

    // TODO: maybe need to replace
    document.dispatchEvent(new CustomEvent('route', {
      detail: {
        page: this.page
      }
    }));
  }

  onClick = event => {
    event.stopPropagation();
    const link = event.target.closest('a');

    if (!link) return;

    const href = link.getAttribute('href');

    if (href && href.startsWith('/')) {
      event.preventDefault();
      this.navigate(href);
    }
  }

  constructor() {
    // Note: for links it must be "click" event
    document.addEventListener('click', this.onClick);
  }

  findRoute (path) {
    const result = {
      path: '',
      match: ''
    };

    for (const route of this.routes) {
      const match = path.match(route.pattern);

      if (match) {
        result.path = route.path;
        result.match = match;
        break;
      }
    }

    return result;
  }

  async changePage (path, match) {
    if (this.page && this.page.destroy) {
      this.page.destroy();
    }

    return await renderPage(path, match);
  }

  navigate (path) {
    this.pushState(path);

    this.onRoute()
      // .catch(error => {
      //   console.error(`Error: ${error}`);
      // });
  }

  pushState (path) {
    window.history.pushState(null, null, path);
  }

  pushQueryParams (name, value)  {
    const { search } = window.location;
    const params = new URLSearchParams(search);

    params.set(name, value);

    window.history.pushState(null, null, `?${params}`);
  }

  addRoute (pattern, path) {
    this.routes.push({pattern, path});
    return this;
  }

  setNotFoundPagePath (path) {
    this.notFoundPagePath = path;
    return this;
  }

  listen () {
    window.addEventListener('popstate', this.onRoute);

    this.onRoute()
      // .catch(error => {
      //   console.error(`Error: ${error}`);
      // });
  }

  destroy () {
    window.removeEventListener('popstate', this.onRoute);
    document.removeEventListener('click', this.onClick);
  }
}

export const router = new Router();
